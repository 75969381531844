import React, { useEffect } from "react"
import { graphql, navigate, useStaticQuery, withPrefix } from "gatsby"

type SiteMetadata = {
  site: {
    siteMetadata: {
      languages: {
        defaultLangKey: string
        langs: string[]
      }
    }
  }
}

const RedirectIndex = () => {
  const data = useStaticQuery<SiteMetadata>(siteMetadata)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const { defaultLangKey } = data.site.siteMetadata.languages
      const homeUrl = withPrefix(`/${defaultLangKey}/`)

      navigate(homeUrl)
    }
  }, [])

  return <></>
}

export default RedirectIndex

export const siteMetadata = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
